<template>
  <div class="avatar">
    <img :src="avatar" :alt="props.altText">
  </div>

  <div v-if="props.isEditing" class="buttons-block">
    <app-button @click="toggleAvatarModal">
      Neuen Avatar hochladen
    </app-button>
  </div>
  <app-image-cropper v-if="showAvatarModal" @close="toggleAvatarModal" @done="updateAvatar"/>
</template>

<script setup>
import { ref, computed } from 'vue';
import AppButton from '@/components/UI/AppButton.vue';
import AppImageCropper from '@/components/UI/AppImageCropper.vue';

const props = defineProps({
  url: {
    type: String,
    default: '',
  },
  altText: {
    type: String,
    default: 'avatar',
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  isPet: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['avatarChanged'])

const newAvatar = ref(null);

const avatar = computed(() => {
  if (newAvatar.value) {
    return `${ process.env.VUE_APP_BASE_URL }${ newAvatar.value.link }`;
  } else if (props.url) {
    return `${ process.env.VUE_APP_BASE_URL }${ props.url }`;
  } else {
    if (props.isPet) {
      return '/img/no-pet-avatar.png';
    }
    return '/img/no-avatar.png';
  }
});

const showAvatarModal = ref(false);
const toggleAvatarModal = () => showAvatarModal.value = !showAvatarModal.value;

const updateAvatar = (avatar) => {
  toggleAvatarModal();
  newAvatar.value = avatar;
  emits('avatarChanged', avatar)
};

// const uploadNewAvatar = async (e) => {
//   try {
//     loading.value = true;
//     const files = e.target.files;
//     filename.value = files[0].name;
//     const response = await postData('/images', files[0]);
//     emits('done', response.id);
//     notificationStore.addNotification({
//       type: 'success',
//       title: 'Erfolg',
//       message: 'Bild wurde erfolgreich hochgeladen',
//     });
//   } catch {
//     notificationStore.addNotification({
//       type: 'error',
//       title: 'Fehler',
//       message: 'Beim Hochladen des Bildes ist ein Fehler aufgetreten',
//     });
//   } finally {
//     loading.value = false;
//   }
//
// };
</script>

<style lang="scss" scoped>
.avatar {
  width: 200px;
  height: 200px;
  border-radius: 200px;
  position: relative;
  background-color: $color-light-gray;
  overflow: hidden;
  margin: 0 auto;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

input {
  display: none;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  margin: 0 auto;

  &.loading {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 100px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      border-top: 2px solid #126B30;
      border-right: 2px solid #126B30;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      animation: 1s linear loading infinite;
    }
  }


  small {
    margin-top: 10px;
  }

  span {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

.buttons-block {
  display: flex;
  justify-content: center;
  margin: 20px 0 50px;
}

@media screen and (max-width: 767px) {
  .avatar {
    width: 150px;
    height: 150px;
  }
  label {
    span {
      margin-bottom: 25px;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//.buttons-block {
//  display: inline-block;
//  margin: 20px auto;
//}
</style>