<template>
  <div class="page">
    <the-header @toggleMobileMenu="toggleMobileMenu"/>
    <div class="container wrapper">
      <aside :class="{'mobile-menu-visible' : isMobileMenuVisible}">
        <the-sidebar @closeMobileMenu="closeMobileMenu"/>
      </aside>
      <main :class="{'gray-bg': $route.meta?.isEditPage}">
        <router-view/>
      </main>
    </div>
    <the-footer/>
  </div>

</template>

<script setup>
import { ref, watch } from 'vue';
import TheHeader from '@/components/TheHeader';
import TheFooter from '@/components/TheFooter';
import TheSidebar from '@/components/TheSidebar';

const isMobileMenuVisible = ref(false);

const closeMobileMenu = () => isMobileMenuVisible.value = false

const toggleMobileMenu = () => {
  isMobileMenuVisible.value = !isMobileMenuVisible.value;
};

watch(isMobileMenuVisible, () => {
  isMobileMenuVisible.value ? document.body.classList.add('hidden') : document.body.classList.remove('hidden');
} )
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  border-right: 2px solid $color-gray;
  border-left: 2px solid $color-gray;
}

aside {
  min-width: 420px;
  border-right: 2px solid $color-gray;
}

main {
  padding: 100px 50px;
}

.gray-bg {
  background-color: $color-light-gray;
}

@media screen and (max-width: 1279px) {
  aside {
    min-width: 350px;
  }
}

@media screen and (max-width: 992px) {
  .gray-bg {
    margin: 0 -20px;
    padding: 40px 20px;
  }

  main {
    padding: 40px 0;
  }

  .wrapper {
    border: none;
  }

  aside {
    position: fixed;
    top: 116px;
    left: -120%;
    height: calc(100vh - 116px);
    width: 100%;
    transition: .35s ease-in-out;
    background-color: $color-white;
    z-index: 2;
    border-right: none;
  }

  .mobile-menu-visible {
    left: 0;

  }
}
@media screen and (max-width: 600px) {
  aside {
    top: 72px;
    height: calc(100vh - 72px);
  }
}
</style>