<template>
  <div class="message">

    <div :class="['message__icon-block', typeClasses]">

      <!--      Success icon-->
      <svg v-if="type === 'success'" xmlns="http://www.w3.org/2000/svg" class="message__icon" viewBox="0 0 20 20"
        fill="currentColor">
        <path fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"/>
      </svg>

      <!--      Warning icon-->
      <svg v-if="type === 'warning'" xmlns="http://www.w3.org/2000/svg" class="message__icon" viewBox="0 0 20 20"
        fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
          clip-rule="evenodd"/>
      </svg>

      <!--      Danger icon-->
      <svg v-if="type === 'error'" xmlns="http://www.w3.org/2000/svg" class="message__icon" viewBox="0 0 20 20"
        fill="currentColor">
        <path fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clip-rule="evenodd"/>
      </svg>

    </div>

    <div class="message__text">
      <div class="message__heading">
        {{ title }}
      </div>
      <div class="message__description">
        {{ message }}
      </div>
    </div>

    <div class="message__button-block">
      <button @click="notificationStore.deleteNotification(props.id)" class="close-button">
        <svg xmlns="http://www.w3.org/2000/svg" class="close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor"
          stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>

  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useNotificationStore } from '@/store/notification';

const notificationStore = useNotificationStore();

const props = defineProps({
  id: {
    type: String,
  },
  type: {
    type: String,
    default: 'success',
    validator(value) {
      return ['success', 'warning', 'error'].includes(
        value.toLowerCase(),
      );
    },
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
});

const typeClasses = computed(() => {
  return {
    'text-green': props.type === 'success',
    'text-orange': props.type === 'warning',
    'text-red': props.type === 'error',
  };
});
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  width: 100%;
  max-width: 370px;
  padding: 20px;
  border-radius: 8px;
  background-color: $color-light-gray;
  border: 2px solid $color-white;

  @include w(720px) {
    min-width: 370px;
  }

  &__icon-block {
    margin-right: 2rem;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
  }

  &__text {
    flex-grow: 1;
  }

  &__heading {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__description {
    color: $color-gray;
    line-height: 150%;
    font-size: 1.6rem;
  }

  &__button-block {
    margin-left: 2rem;
  }
}

.text-green {
 color: $color-accent;
}

.text-orange {
 color: $color-accent;
}

.text-red {
 color: $color-danger;
}

.close-button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  color: $color-gray;
}
.close-icon {
  width: 24px;
  height: 24px;
}
</style>
