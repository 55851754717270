import { defineStore } from 'pinia';

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notificationList: [],
  }),
  
  getters: {
    // double: (state) => state.count * 2,
  },
  
  actions: {
    addNotification(notification) {
      this.notificationList.unshift({id: Date.now().toLocaleString(), ...notification});
      this.hideLastNotification();
    },
    
    deleteNotification(id) {
      this.notificationList = this.notificationList.filter(item => item.id !== id);
    },
    
    hideLastNotification() {
      setTimeout(() => {
        if (this.notificationList.length) {
          this.notificationList.splice(-1, 1);
        }
      }, 5000);
    },
  },
});