<template>
  <component :is="layout">
    <router-view/>
  </component>
  <app-notification-list/>
</template>

<script setup>
import { onBeforeMount, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/store/user';
import MainLayout from '@/layouts/MainLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import AppNotificationList from '@/components/notifications/AppNotificationList';


const route = useRoute();
const userStore = useUserStore();

const layout = computed(() =>
  route.meta.layout === 'main' ? MainLayout : EmptyLayout,
);

onBeforeMount(async () => {
  const token = localStorage.getItem('token');
  if (token) await userStore.getUserData();
});
</script>

<style lang="scss" scoped></style>