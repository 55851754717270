<template>
  <div class="notification-list">

    <transition-group
      move-class="anim"
      enter-active-class="anim"
      leave-active-class="anim"
      enter-from-class="anim__from-to"
      leave-to-class="anim__from-to"
    >

      <app-notification-message
        v-for="notification in notificationList"
        :id="notification.id"
        :key="notification.id"
        :type="notification.type"
        :title="notification.title"
        :message="notification.message"/>

    </transition-group>

  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useNotificationStore } from '@/store/notification';
import AppNotificationMessage from '@/components/notifications/AppNotificationMessage';

const notificationStore = useNotificationStore();

const notificationList = computed(() => notificationStore.notificationList);
</script>

<style lang="scss" scoped>

.notification-list {
  position: fixed;
  top: 10rem;
  right: 0;
  display: grid;
  gap: 2rem;
  padding: 2rem;
}

.anim {
  transition: all .5s ease-in-out;

  &__from-to {
    opacity: 0;
    transform: translateX(2rem);
  }
}
</style>
