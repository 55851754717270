<template>
  <div class="block">
    <slot/>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5rem;
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>