<template>
  <button @click="emits('click')" class="button" :class="classes" v-bind="$attrs">
    <slot/>
  </button>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    rounded: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'green',
    },
    outline: {
      type: Boolean,
      default: false,
    },
  })
;

const emits = defineEmits({
  'click': null,
});

const classes = computed(() => ({
  'rounded': props.rounded,
  'green': props.color === 'green' && !props.outline,
  'red': props.color === 'red' && !props.outline,
  'gray': props.color === 'gray' && !props.outline,
  'outline-green': props.color === 'green' && props.outline,
  'outline-red': props.color === 'red' && props.outline,
  'outline-gray': props.color === 'gray' && props.outline,
}));
</script>

<style scoped lang="scss">
.button {
  height: 52px;
  padding: 10px 23px;
  border-radius: 8px;
  border: none;
  font-size: 1.6rem;
  font-weight: 700;
  min-width: 196px;
  line-height: 1;
  cursor: pointer;
  transition: background-color .35s, color, .35s;
  &:disabled, &:disabled:hover {
    background-color: $color-gray;
    border: 2px solid $color-gray;
  }

  &__icon {
    margin-left: 17px
  }
}

.rounded {
  border-radius: 50px;
}

.green {
  background-color: $color-accent;
  color: $color-white;
  border: 2px solid $color-accent;

  &:hover {
    background-color: lighten($color-accent, 5%);
  }
}

.red {
  background-color: $color-danger;
  color: $color-white;
  border: 2px solid $color-danger;

  &:hover {
    background-color: lighten($color-danger, 5%);
  }
}

.gray {
  background-color: $color-light-gray;
  color: $color-black;
  border: 2px solid $color-gray;

  &:hover {
    background-color: lighten($color-light-gray, 5%);
  }
}

.outline-green {
  background-color: transparent;
  border: 2px solid $color-accent;
  color: $color-accent;

  &:hover {
    background-color: $color-accent;
    color: $color-white;
  }
}

</style>