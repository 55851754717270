<template>
  <div class="content">
    <app-avatar :url="userStore['userAvatar']" altText="User avatar"/>
    <h1>{{ userStore['name'] }}</h1>
    <app-info-block class="details">
      <app-info-item title="Telefon:" :value="userStore['phone']"/>
      <app-info-item title="E-Mail Adresse:" :value="userStore['email']"/>
      <app-info-item @click="$router.push({name: 'pet-list'})" isLink title="Hunde:" :value="userStore['petCount']"/>
      <app-info-item @click="$router.push({name: 'order-list'})" isLink title="Bestellungen:" :value="userStore['orderCount']"/>
      <app-info-item @click="$router.push({name: 'address-list'})" isLink title="Adresse:" :value="userStore['addressCount']"/>
    </app-info-block>
    <div class="btn-wrap">
      <app-button @click="$router.push({name: 'edit-profile'})" rounded>Profil bearbeiten</app-button>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useUserStore } from '@/store/user';
import AppButton from '@/components/UI/AppButton';
import AppAvatar from '@/components/UI/AppAvatar';
import AppInfoBlock from '@/components/UI/AppInfoBlock';
import AppInfoItem from '@/components/UI/AppInfoItem';

const userStore = useUserStore();

onBeforeMount(async () => {
  await userStore.getUserData();
})

</script>

<style scoped>

.content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.details {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .content {
    gap: 25px;
  }
}

</style>