<template>
  <teleport to="body">
    <div class="modal" @click.self="$emit('close')">
      <div class="modal__content">
        <div class="modal__header">
          <button @click="$emit('close')" class="modal__close-button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="currentColor"/>
            </svg>
          </button>
        </div>
        <div class="modal__body" :class="{'modal__body_reversed': !image.src}">
          <div class="cropper-wrapper" ref="cropperWrapper">
            <cropper
              ref="cropper"
              class="cropper"
              :src="image.src"
              :stencil-props="{ aspectRatio: 10 / 10 }"
              :style="`max-height: ${myCropperMaxHeight}px`"
              imageRestriction="fit-area"
            />
          </div>
          <div class="button-wrapper">
            <label class="label" for="file">
              <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" fill="white"/>
                <path
                  d="M26.5625 42.1875C26.5625 40.8062 27.1112 39.4814 28.088 38.5047C29.0647 37.5279 30.3895 36.9792 31.7708 36.9792H34.1927C35.05 36.9793 35.894 36.7678 36.6499 36.3635C37.4058 35.9592 38.0503 35.3746 38.526 34.6615L40.6406 31.4844C41.1164 30.7713 41.7608 30.1866 42.5168 29.7823C43.2727 29.378 44.1167 29.1666 44.974 29.1667H55.026C55.8833 29.1666 56.7273 29.378 57.4832 29.7823C58.2392 30.1866 58.8836 30.7713 59.3594 31.4844L61.474 34.6615C61.9497 35.3746 62.5942 35.9592 63.3501 36.3635C64.106 36.7678 64.95 36.9793 65.8073 36.9792H68.2292C69.6105 36.9792 70.9353 37.5279 71.912 38.5047C72.8888 39.4814 73.4375 40.8062 73.4375 42.1875V65.625C73.4375 67.0064 72.8888 68.3311 71.912 69.3079C70.9353 70.2846 69.6105 70.8334 68.2292 70.8334H31.7708C30.3895 70.8334 29.0647 70.2846 28.088 69.3079C27.1112 68.3311 26.5625 67.0064 26.5625 65.625V42.1875Z"
                  stroke="#126B30"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"/>
                <path
                  d="M57.8125 52.6042C57.8125 54.6762 56.9894 56.6633 55.5243 58.1285C54.0591 59.5936 52.072 60.4167 50 60.4167C47.928 60.4167 45.9409 59.5936 44.4757 58.1285C43.0106 56.6633 42.1875 54.6762 42.1875 52.6042C42.1875 50.5322 43.0106 48.545 44.4757 47.0799C45.9409 45.6148 47.928 44.7917 50 44.7917C52.072 44.7917 54.0591 45.6148 55.5243 47.0799C56.9894 48.545 57.8125 50.5322 57.8125 52.6042V52.6042Z"
                  stroke="#126B30"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"/>
                <circle cx="32.8125" cy="34.375" r="12.5" fill="white"/>
                <path fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M33.8545 29.1666H31.7712V33.3333H27.6045V35.4166H31.7712V39.5833H33.8545V35.4166H38.0212V33.3333H33.8545V29.1666Z"
                  fill="#126B30"/>
                <circle cx="32.8127" cy="34.375" r="9.41667" stroke="#126B30" stroke-width="2"/>
              </svg>
              <span>Bild auswählen</span>
            </label>
            <input
              id="file"
              type="file"
              ref="file"
              @change="loadImage($event)"
              accept="image/*"
              style="visibility: hidden"
            />
          </div>
        </div>
        <div class="modal__footer">
          <app-button v-show="!!image.src" @click="crop">Speichern</app-button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { postData } from '@/use/api.js';
import { useUserStore } from '@/store/user';
import { useNotificationStore } from '@/store/notification';
import { usePetsStore } from '@/store/pets';
import AppButton from '@/components/UI/AppButton.vue';

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
}

export default {
  components: {
    Cropper,
    AppButton,
  },
  emits: ['close', 'done'],
  setup() {
    const notificationStore = useNotificationStore();
    const userStore = useUserStore();
    const petsStore = usePetsStore();
    return {notificationStore, userStore, petsStore};
  },
  data() {
    return {
      image: {
        src: null,
        type: null,
      },
      myCropperMaxHeight: 0
    };
  },
  mounted() {
    this.myCropperMaxHeight = this.$refs.cropperWrapper.clientHeight - 52;
  },
  methods: {
    crop() {
      const {canvas} = this.$refs.cropper.getResult();
      canvas.toBlob(async (blob) => {
        try {
          const avatar = await postData('/images', blob);
          this.$emit('done', avatar);
          this.notificationStore.addNotification({
            type: 'success',
            title: 'Erfolg',
            message: 'Bild wurde erfolgreich hochgeladen',
          });
        } catch {
          this.notificationStore.addNotification({
            type: 'error',
            title: 'Fehler',
            message: 'Beim Hochladen des Bildes ist ein Fehler aufgetreten',
          });
        }
      }, this.image.type);
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      };
    },
    loadImage(event) {
      // Reference to the DOM input element
      const {files} = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },
  },
  unmounted() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
};
</script>

<style lang="scss" scoped>

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.781);
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
  @media screen and (max-width: 400px) {
    padding: 10px;
  }

  &__content {
    background-color: $color-white;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    &_reversed {
      flex-direction: column-reverse;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close-button {
    background-color: transparent;
    border: none;
    transition: color .35s;

    &:hover {
      color: $color-accent;
    }
  }
}

.cropper-wrapper {
  flex-grow: 1;
}

.cropper {
  min-height: 30vh;
  overflow: hidden;
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
