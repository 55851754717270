import { defineStore } from 'pinia';
import { deleteData, editData, getData, postData } from '@/use/api';
import router from '@/router';

export const useUserStore = defineStore('user', {
  state: () => ({
    id: null,
    name: '',
    email: '',
    phone: '',
    avatar: {
      id: '',
      url: '',
    },
    petCount: '',
    orderCount: '',
    addressCount: ''
  }),
  
  getters: {
    
    userAvatar: (state) => state.avatar.id ? `${ state.avatar.url }` : ``,
    
    addressToEdit: (state) => (addressId) => state.userAddresses.filter(address => address.id === addressId),
    
  },
  
  actions: {
    
    async createUserAccount(userData) {
      return await postData('/auth/register', userData);
    },
    
    async getUserData() {
      const response = await getData('/users');
      if (!response.error) {
        this.id = response.id;
        this.name = response.name;
        this.email = response.email;
        this.phone = response.phoneNumber;
        this.avatar.id = response?.image?.id;
        this.avatar.url = response?.image?.link;
        this.petCount = response.petCount;
        this.orderCount = response.orderCount;
        this.addressCount = response.addressCount;
      }
      return response;
    },
    
    async logOut() {
      const response = await postData('auth/logout');
      if (!response.error) {
        await router.push({name: 'log-in'});
        this.id = null;
        this.name = '';
        this.email = '';
        this.phone = '';
        this.role = '';
        this.avatar.id = '';
        this.avatar.url = '';
        this.petCount = '';
        this.orderCount = '';
        this.addressCount = '';
        localStorage.clear();
      }
    },
    
    async editUserData(newUserData) {
      const response = await editData(`/users`, newUserData);
      if (!response.error) {
        this.name = newUserData.name;
        this.email = newUserData.email;
        this.phone = newUserData.phoneNumber;
        this.role = newUserData.role;
      }
      return response;
    },
    
    hotAvatarUpdate(id, url) {
      this.avatar.id = id;
      this.avatar.url = url;
    },
    
    async changeUserPassword(oldPassword, newPassword) {
      return postData('/auth/change-pwd', {
        oldPassword: oldPassword, newPassword: newPassword,
      });
    },
    
    async deleteUser() {
      return await deleteData(`/users`);
    },
    
  },
});
