<template>
  <div class="container">
    <div class="logo">
      <a :href="url">
        <img src="@/assets/img/logo-color.svg" alt="Vetohome logo">
      </a>
    </div>
    <router-view/>
  </div>

</template>

<script setup>
import { computed } from 'vue';

const url = computed(() => process.env.VUE_APP_VETOMOL_BASE_URL);
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-bottom: 48px;

  &:hover {
    cursor: pointer;
  }
}

.container {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>