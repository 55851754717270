import { defineStore } from 'pinia';
import { deleteData, editData, getData, postData } from '@/use/api';
// import router from '@/router';

export const usePetsStore = defineStore('pets', {
  state: () => ({
    petList: [],
  }),
  
  getters: {
    
    petAvatar:
      (state) => state.avatar
        ? `${ process.env.VUE_APP_BASE_URL }/static/${ state.avatar }`
        : `${ process.env.VUE_APP_CLIENT_BASE_URL }/img/no-avatar.png`,
  },
  
  actions: {
    
    async getPetList() {
      const response = await getData('/pets');
      if (!response.error) {
        this.petList = [];
        this.petList = [...response.items];
      }
    },
    
    async getPetDetails(id) {
      return await getData(`/pets/${ id }`);
    },
    
    async createPet(newPetData) {
      return await postData('/pets', newPetData);
    },
    
    async updatePet(petId, newPetData) {
      return await editData(`/pets/${ petId }`, newPetData);
    },
    
    async deletePet(id) {
      return await deleteData(`/pets/${ id }`);
    },
    
    
  },
});
