import { createRouter, createWebHistory } from 'vue-router';
import ProfilePage from '@/views/UserProfilePage';

const routes = [
  {
    path: '/log-in',
    name: 'log-in',
    component: () => import('@/views/auth/LogInPage'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/views/auth/UserRegistrationPage'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/password-restore',
    name: 'password-restore',
    component: () => import('@/views/auth/ForgotPasswordPage'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/reset',
    name: 'password-reset',
    component: () => import('@/views/auth/PasswordResetPage.vue'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/password-change',
    name: 'password-change',
    component: () => import('@/views/ChangePasswordPage'),
    meta: {
      layout: 'main',
      isEditPage: true,
      activeMenuLink: 'profile',
      auth: true,
    },
  },
  {
    path: '/activate',
    name: 'email-confirmation',
    component: () => import('@/views/auth/EmailConfirmationPage.vue'),
    meta: {
      layout: 'empty',
      auth: false,
    },
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('@/views/RefundPage.vue'),
    meta: {
      layout: 'empty',
      auth: false,
    },
  },
  {
    path: '/',
    name: 'profile',
    component: ProfilePage,
    meta: {
      layout: 'main',
      activeMenuLink: 'profile',
      auth: true,
    },
  },
  {
    path: '/edit-profile',
    name: 'edit-profile',
    component: () => import('@/views/UserEditPage'),
    meta: {
      layout: 'main',
      isEditPage: true,
      activeMenuLink: 'profile',
      auth: true,
    },
  },
  {
    path: '/pets',
    name: 'pet-list',
    component: () => import('@/views/PetListPage'),
    meta: {
      layout: 'main',
      activeMenuLink: 'pet',
      auth: true,
    },
  },
  {
    path: '/pets/:id',
    name: 'pet-details',
    component: () => import('@/views/PetDetailsPage'),
    meta: {
      layout: 'main',
      activeMenuLink: 'pet',
      auth: true,
    },
  },
  {
    path: '/pets/create',
    name: 'pet-create',
    component: () => import('@/views/CreateUpdatePetPage'),
    meta: {
      layout: 'main',
      isEditPage: true,
      activeMenuLink: 'pet',
      auth: true,
    },
  },
  {
    path: '/pets/edit/:id',
    name: 'pet-edit',
    component: () => import('@/views/CreateUpdatePetPage'),
    meta: {
      layout: 'main',
      isEditPage: true,
      activeMenuLink: 'pet',
      auth: true,
    },
  },
  {
    path: '/addresses',
    name: 'address-list',
    component: () => import('@/views/AddressListPage'),
    meta: {
      layout: 'main',
      activeMenuLink: 'address',
      auth: true,
    },
  },
  {
    path: '/addresses/create',
    name: 'address-create',
    component: () => import('@/views/CreateUpdateAddressPage'),
    meta: {
      layout: 'main',
      isEditPage: true,
      activeMenuLink: 'address',
      auth: true,
    },
  },
  {
    path: '/addresses/edit/:id',
    name: 'address-edit',
    component: () => import('@/views/CreateUpdateAddressPage'),
    meta: {
      layout: 'main',
      isEditPage: true,
      activeMenuLink: 'address',
      auth: true,
    },
  },
  {
    path: '/addresses/:id',
    name: 'address-details',
    component: () => import('@/views/AddressDetailsPage'),
    meta: {
      layout: 'main',
      activeMenuLink: 'address',
      auth: true,
    },
  },
  {
    path: '/orders',
    name: 'order-list',
    component: () => import('@/views/OrderListPage'),
    meta: {
      layout: 'main',
      activeMenuLink: 'order',
      auth: true,
    },
  },
  {
    path: '/orders/:id',
    name: 'order-details',
    component: () => import('@/views/OrderDetailsPage'),
    meta: {
      layout: 'main',
      activeMenuLink: 'order',
      auth: true,
    },
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import('@/views/ImpressumPage'),
    meta: {
      layout: 'main',
      auth: true,
    },
  },
  {
    path: '/agb',
    name: 'agb',
    component: () => import('@/views/AgbPage'),
    meta: {
      layout: 'main',
      auth: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/PrivacyPage'),
    meta: {
      layout: 'main',
      auth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutPage.vue'),
    meta: {
      layout: 'main',
      auth: true,
    },
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('@/views/SubscriptionsPage.vue'),
    meta: {
      layout: 'main',
      activeMenuLink: 'subscriptions',
      auth: true,
    },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {top: 0};
    }
  },
});

router.beforeEach((to, from, next) => {
  
  const requireAuth = to.meta.auth;
  const token = localStorage.getItem('token');
  
  if (requireAuth && !token) {
    next({name: 'log-in'});
  } else {
    next();
  }
  
});

export default router;
