<template>
  <footer class="footer">
    <div class="footer__top">
      <img src="@/assets/img/logo.svg" alt="logo" class="footer__logo">
    </div>
    <div class="footer__middle">
      <div class="container">
        <nav class="footer__menu">
          <ul>
            <li>
              <router-link :to="{name: 'privacy'}">DATENSCHUTZ</router-link>
            </li>
            <li>
              <router-link :to="{name: 'about'}">ÜBER UNS</router-link>
            </li>
            <li>
              <router-link :to="{name: 'agb'}" href="#">AGB</router-link>
            </li>
            <li>
              <router-link :to="{name: 'impressum'}">IMPRESSUM</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container">
        <p class="copyright">Copyright &copy; {{ currentYear }}. VETOMOL. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    currentYear: () => new Date().getFullYear(),
  },
};
</script>

<style scoped lang="scss">
.footer {
  border-top: 2px solid #707070;

  &__top {
    text-align: center;
    padding-top: 29px;
    padding-bottom: 26px;
  }

  &__logo {
    width: 182px;
  }

  &__middle {
    background: #126B30;
    padding: 40px 0 43px;
  }

  &__menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;

      li {
        a {
          font-weight: 700;
          font-size: 24px;
          line-height: 1;
          text-transform: uppercase;
          color: #FFFFFF;
          text-decoration: none;
          padding: 15px 51px;
          display: inline-block;
        }
      }
    }
  }

  &__bottom {
    padding: 40px 20px 39px;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 16px;
      color: #001219;
      margin: 0;

      img {
        margin-left: 12px;
      }

      &.copyright {
        font-size: 15px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .footer__bottom {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media screen and (max-width: 992px) {
    &__menu {
      ul {
        li {
          a {
            font-size: 20px;
            padding: 17px 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    &__menu {
      ul {
        flex-wrap: wrap;

        li {
          width: 100%;

          a {
            font-size: 18px;
            width: 100%;
            text-align: center;
            box-sizing: border-box;
          }
        }
      }
    }
    &__bottom {
      padding: 19px 0 28px;

      .container {
        flex-wrap: wrap;

        p {
          width: 100%;
          text-align: center;
          font-size: 12px;

          &.copyright {
            margin-top: 28px;
            order: 1;
            font-size: 12px;
          }
        }
      }
    }
    &__middle {
      padding: 35px 0 34px;
    }
    &__top {
      padding-top: 24px;
      padding-bottom: 20px;
    }
    &__logo {
      width: 125px;
    }
  }
}
</style>