import axios from 'axios';
import router from '@/router';
import errorText from '@/use/errors';

const api = axios.create();

api.interceptors.request.use((config) => {
  config.baseURL = process.env.VUE_APP_API_URL;
  if (localStorage.getItem('token')) {
    config.headers = {
      Authorization: `Bearer ${ localStorage.getItem('token') }`,
    };
  }
  
  return config;
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      router.push({name: 'log-in'});
    }
    return error;
  },
);

const getData = async (url) => {
  try {
    const response = await api.get(url);
    if (!response.data && !(response.status >= 200 && response.status < 300)) {
      return errorText(response);
    }
    return response.data;
  } catch (e) {
    return errorText(e);
  }
};

const postData = async (url, payload) => {
  try {
    const response = await api.post(url, payload);
    if (!response.data && !(response.status >= 200 && response.status < 300)) {
      return errorText(response);
    }
    return response.data;
  } catch (e) {
    return errorText(e);
  }
};

const deleteData = async (url, payload) => {
  try {
    const response = await api.delete(url, {data: payload});
    if (!response.data && !(response.status >= 200 && response.status < 300)) {
      return errorText(response);
    }
    return response.data;
  } catch (e) {
    return errorText(e);
  }
};

const editData = async (url, payload) => {
  try {
    const response = await api.put(url, payload);
    if (!response.data && !(response.status >= 200 && response.status < 300)) {
      return errorText(response);
    }
    return response.data;
  } catch (e) {
    return errorText(e);
  }
};

export { getData, postData, deleteData, editData, api };
